<ng-template #timeOffSetupGuideDialogContent>
    <img src="assets/img/time-off/time-off-quickstart-modal-banner.png" alt="Calendar Icon and Time Off image" />

    <div class="heading padding-top-3">
        {{ 'time-off-v3.setup-guide-initial-dialog.manageYourTimeOffWithEase' | translate }}
    </div>
    <h1 id="dialog-header" class="margin-top-1">
        {{ 'time-off-v3.setup-guide-initial-dialog.letsSetUpYourTimeOff' | translate }}
    </h1>
    <p class="padding-y-2">{{ 'time-off-v3.setup-guide-initial-dialog.weHavePreparedASetupGuide' | translate }}</p>

    <ui-dialog-footer>
        <ui-button [loading]="isLoading" [submit]="true" (click)="getStarted()" [type]="'primary'">{{
            'time-off-v3.setup-guide-initial-dialog.getStarted' | translate
        }}</ui-button>
    </ui-dialog-footer>
</ng-template>
