import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AnalyticEvents } from '@app/enums';
import { AnalyticService } from '@app/services';
import { SetupGuide } from '@app/models/company/setup-guide.model';
import { SetupGuideService } from '@app/modules/company/services';
import { PlatformModule } from '@app/platform.module';
import { AuthService, DialogService, NotifyService } from '@app/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-setup-guide-initial-dialog',
    templateUrl: './setup-guide-initial-dialog.template.html',
    styleUrls: ['./setup-guide-initial-dialog.style.scss'],
    standalone: true,
    imports: [TranslateModule, PlatformModule, CommonModule, FormsModule],
})
export class SetupGuideInitialDialogComponent {
    @Input() setupGuide = new SetupGuide();
    @ViewChild('timeOffSetupGuideDialogContent', { static: true })
    timeOffSetupGuideDialogContent!: TemplateRef<unknown>;

    isDialogOpen = false;
    isLoading = false;

    constructor(
        private setupGuideService: SetupGuideService,
        private notify: NotifyService,
        private dialogService: DialogService,
        private viewContainerRef: ViewContainerRef,
        private auth: AuthService,
        private router: Router,
        private analyticService: AnalyticService
    ) {}

    async getStarted(): Promise<void> {
        this.isLoading = true;
        try {
            await this.setupGuideService.initialActionComplete(this.setupGuide);
            await this.auth.refreshCompany();
            this.dialogService.close();
            this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeGetStarted);
            this.router.navigate(['/time-off/v3', 'types-and-policies']);
        } catch (err) {
            this.notify.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    ngAfterViewInit(): void {
        this.showDialog();
    }

    showDialog(): void {
        if (this.setupGuide.initialActionCompletedAt) {
            return;
        }

        this.dialogService.setPortal(this.timeOffSetupGuideDialogContent, this.viewContainerRef, {
            open: this.isDialogOpen,
            isCloseable: false,
            showHeader: false,
            size: 'small',
        });

        this.dialogService.open();
    }
}
